export const Permissions = user => {
  // Petty Cash Team

  // const allRoles = [
  //   'ACCOUNTANT',
  //   'FINANCE_ADMIN',
  // 'SUB_ADMIN',
  //   'AREA_SALES_MANAGER',
  //   'REGIONAL_SALES_MANAGER',
  //   'QUALITY_AUDIT_MANAGER',
  //   'QUALITY_AUDIT_EXECUTIVE',
  //   'QUALITY_AUDIT_HEAD',
  //   'STORE_SALES_MANAGER',
  //   'DEPARTMENT_MANAGER',
  //   'SUPPLY_CHAIN/LP',
  //   'MARKETING',
  //   'FINANCE',
  //   'IT',
  //   'DOWNLOAD_REPORTS',
  //   'TEST_ROLE',
  //   'ADMIN'
  // ];

  const isAccountant = user.roles.filter(role => role.name === 'ACCOUNTANT');
  if (isAccountant.length) {
    return ['pettyCashNavBarItemPermission'];
  }

  const financeAdminRole = user.roles.filter(role => role.name === 'FINANCE_ADMIN');
  if (financeAdminRole.length) {
    return ['pettyCashNavBarItemPermission', 'staffNavBarItemPermission', 'userPermission', 'storesPermission'];
  }

  // if asm rsm
  const ifAsmRsm = user.roles.filter(
    role => role.name === 'AREA_SALES_MANAGER' || role.name === 'REGIONAL_SALES_MANAGER'
  );
  if (ifAsmRsm.length) {
    return [
      'reportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'dynamicFormNavBarPermission',
      'eventNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'staffNavBarItemPermission',
      'pettyCashNavBarItemPermission',
      'reportPermission',
      'viewChecklistPermission',
      'fillChecklistPermission',
      'popPermission',
      'ticketsPermission',
      'osaReportPermission',
      'kycPermission',
      'kycInstitutionalPermission',
      'storeLicensedPermission',
      'promoCodePermission',
      'giftIssuancePermission',
      'commentsPermission',
      'notesPermission',
      'storeDocumentPermission',
      'videosPermission',
      'userPermission',
      'storesPermission'
    ];
  }

  const foodSafetyTeam = user.roles.filter(
    role => role.name === 'QUALITY_AUDIT_MANAGER' || role.name === 'QUALITY_AUDIT_EXECUTIVE'
  );
  if (foodSafetyTeam.length) {
    return [
      'reportNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'ticketsPermission',
      'kycPermission',
      'commentsPermission'
    ];
  }

  // food safety admin
  const foodSafetyAdmin = user.roles.filter(role => role.name === 'QUALITY_AUDIT_HEAD');
  if (foodSafetyAdmin.length) {
    return [
      'reportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'foodSafetyPermission',
      'staffNavBarItemPermission',
      'userPermission',
      'viewFoodSafetyChecklistPermission',
      'ticketsPermission',
      'kycPermission',
      'commentsPermission'
    ];
  }

  // store sales manager
  const subAdmin = user.roles.filter(role => role.name === 'SUB_ADMIN');
  if (subAdmin.length) {
    return [
      'specialReportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'dynamicFormNavBarPermission',
      'eventNavBarItemPermission',
      'npsNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'staffNavBarItemPermission',
      'pettyCashNavBarItemPermission',
      'reportPermission',
      'foodSafetyPermission',
      'viewChecklistPermission',
      'viewFoodSafetyChecklistPermission',
      'fillChecklistPermission',
      'npsVenderPermission',
      'npsCustomerPermission',
      'popPermission',
      'ticketsPermission',
      'osaReportPermission',
      'kycPermission',
      'kycInstitutionalPermission',
      'storeLicensedPermission',
      'promoCodePermission',
      'giftIssuancePermission',
      'commentsPermission',
      'notesPermission',
      'schedulesPermission',
      'tagsPermission',
      'storeDocumentPermission',
      'videosPermission',
      'userPermission',
      'storesPermission'
    ];
  }
  const isStoreSalesManager = user.roles.filter(
    role => role.name === 'STORE_SALES_MANAGER' || role.name === 'DEPARTMENT_MANAGER'
  );
  if (isStoreSalesManager.length) {
    return [
      'specialReportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'dynamicFormNavBarPermission',
      'eventNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'pettyCashNavBarItemPermission',
      'reportPermission',
      'foodSafetyPermission',
      'fillChecklistPermission',
      'popPermission',
      'ticketsPermission',
      'osaReportPermission',
      'kycPermission',
      'kycInstitutionalPermission',
      'storeLicensedPermission',
      'promoCodePermission',
      'giftIssuancePermission',
      'commentsPermission',
      'notesPermission',
      'schedulesPermission',
      'tagsPermission',
      'storeDocumentPermission',
      'userPermission',
      'storesPermission'
    ];
  }

  //  NPS USER
  const isSupply = user.roles.filter(role => role.name === 'SUPPLY_CHAIN/LP');
  const isMarketing = user.roles.filter(role => role.name === 'MARKETING');
  const isFinance = user.roles.filter(role => role.name === 'FINANCE');
  const isIT = user.roles.filter(role => role.name === 'IT');
  const isDownloadTeam = user.roles.filter(role => role.name === 'DOWNLOAD_REPORTS');
  if (isSupply.length || isMarketing.length || isFinance.length || isIT.length || isDownloadTeam.length) {
    return [
      'reportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'viewChecklistPermission',
      'fillChecklistPermission',
      'dynamicFormNavBarPermission',
      'eventNavBarItemPermission',
      'npsNavBarItemPermission',
      'npsCustomerPermission',
      'npsVenderPermission',
      'moreFunctionNavBarItemPermission',
      'popPermission',
      'ticketsPermission',
      'osaReportPermission',
      'kycPermission',
      'storeLicensedPermission',
      'promoCodePermission',
      'commentsPermission',
      'notesPermission',
      'storeDocumentPermission'
    ];
  }
  // Test User
  const isTest = user.roles.filter(role => role.name === 'TEST_ROLE');
  if (isTest.length) {
    return [
      'dashboardNavBarItemPermission',
      'specialReportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'dynamicFormNavBarPermission',
      'eventNavBarItemPermission',
      'npsNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'staffNavBarItemPermission',
      'pettyCashNavBarItemPermission',
      'reportPermission',
      'foodSafetyPermission',
      'viewChecklistPermission',
      'viewFoodSafetyChecklistPermission',
      'fillChecklistPermission',
      'npsVenderPermission',
      'npsCustomerPermission',
      'supportTicketsPermission',
      'popPermission',
      'ticketsPermission',
      'osaReportPermission',
      'kycPermission',
      'kycInstitutionalPermission',
      'storeLicensedPermission',
      'promoCodePermission',
      'giftIssuancePermission',
      'commentsPermission',
      'notesPermission',
      'schedulesPermission',
      'tagsPermission',
      'storeDocumentPermission',
      'videosPermission',
      'userPermission',
      'rolesPermission',
      'storesPermission'
    ];
  }

  // Spencer Admin
  const isAdmin = user.roles.filter(role => role.name === 'ADMIN');
  if (isAdmin.length) {
    return [
      'dashboardNavBarItemPermission',
      'specialReportNavBarItemPermission',
      'checklistNavBarItemPermission',
      'dynamicFormNavBarPermission',
      'eventNavBarItemPermission',
      'npsNavBarItemPermission',
      'moreFunctionNavBarItemPermission',
      'staffNavBarItemPermission',
      'pettyCashNavBarItemPermission',
      'reportPermission',
      'foodSafetyPermission',
      'viewChecklistPermission',
      'viewFoodSafetyChecklistPermission',
      'fillChecklistPermission',
      'npsVenderPermission',
      'npsCustomerPermission',
      'popPermission',
      'ticketsPermission',
      'osaReportPermission',
      'kycPermission',
      'kycInstitutionalPermission',
      'storeLicensedPermission',
      'promoCodePermission',
      'giftIssuancePermission',
      'commentsPermission',
      'notesPermission',
      'schedulesPermission',
      'tagsPermission',
      'storeDocumentPermission',
      'videosPermission',
      'userPermission',
      'rolesPermission',
      'storesPermission'
    ];
  }
};
