import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common';
import auth from './modules/auth';
import alert from './modules/alert';
import darkMode from './modules/darkMode';
import user from './modules/user';
import permission from './modules/permission';
import setting from './modules/setting';
import store from './modules/store';
import assettransfer from './modules/assettransfer';
import report from './modules/report';
import checklist from './modules/checklist';
import checklistV2 from './modules/checklistV2';
import checklistFill from './modules/checklistFill';
import comment from './modules/comment';
import role from './modules/role';
import dashboard from './modules/dashboard';
import dashChart from './modules/dashChart';
import mis from './modules/mis';
import feedback from './modules/feedback';
import notes from './modules/notes';
import gift from './modules/gift';
import form from './modules/form';
import bulk from './modules/bulk';
import schedule from './modules/schedule';
import tags from './modules/tags';
import brand from './modules/brand';
import events from './modules/events';
import customerNumber from './modules/customerNumber';
import photoSharing from './modules/photoSharing';
import video from './modules/video';
import cst from './modules/cst';
import cstSubmission from './modules/cstSubmission';
import promoCode from './modules/promoCode';
import cstSubmissionId from './modules/cstSubmissionId';
import kyc from './modules/kyc';
import license from './modules/license';
import document from './modules/document';
import rating from './modules/rating';
import npsInternal from './modules/npsInternal';
import barcode from './modules/barcode';
import contactUs from './modules/contactUs';
import popPrint from './modules/popPrint';
import checklistV2Fill from './modules/checklistV2Fill';
import pettyCash from './modules/pettyCash';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    auth,
    alert,
    darkMode,
    user,
    role,
    permission,
    setting,
    report,
    store,
    checklist,
    checklistV2,
    checklistFill,
    comment,
    dashboard,
    dashChart,
    mis,
    feedback,
    notes,
    gift,
    form,
    bulk,
    schedule,
    tags,
    brand,
    events,
    customerNumber,
    photoSharing,
    video,
    cst,
    cstSubmission,
    promoCode,
    cstSubmissionId,
    kyc,
    license,
    document,
    rating,
    assettransfer,
    npsInternal,
    barcode,
    contactUs,
    popPrint,
    pettyCash,
    checklistV2Fill
  },
  strict: true
});
